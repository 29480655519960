
import { addAPI, editAPI } from '../api'
import { defineComponent, reactive, ref, toRefs } from 'vue'
import { ElMessage } from 'element-plus'
import { toResult } from '@/utils/toResult'
import { required, capitalLetter } from '@/utils/rules'
export default defineComponent({
  name: '',
  props: {
    modelValue: {
      type: Boolean,
      required: true,
      default: false
    },
    row: {
      type: Object,
      default: () => {}
    },
    title: {
      type: String,
      default: '新增车品牌'
    }
  },
  setup(props, { emit }) {
    const refFrom = ref<HTMLElement | any>(null)
    const state = reactive({
      visible: props.modelValue,
      formData: ref<any>({
        state: 1
      }),
      rules: {
        name: [required],
        brandLetter: [required, capitalLetter]
      }
    })

    if (props.title === '编辑品牌') {
      state.formData = Object.assign({}, props.row)
    }

    /**
     * @description: 取消（关闭）当前 dialog
     * @param {*} void
     * @return {*}
     */
    const onCancel = (): void => {
      state.visible = false
      emit('update:modelValue', false)
      emit('update:list')
    }

    /**
     * @description: 表单提交
     * @param {*}
     * @return {*}
     */
    const onSubmit = async p => {
      let requestAPI = addAPI
      if (props.title === '编辑品牌') requestAPI = editAPI

      p.brandLetter = p.brandLetter.toUpperCase()
      const [err, res, msg] = await toResult(requestAPI(p))
      if (err) return
      console.log(res)
      ElMessage.success(msg || '成功')
      onCancel()
    }

    /**
     * @description: 表单提交前校验
     * @param {*}
     * @return {*}
     */
    const onValidate = () => {
      console.log(refFrom.value)
      refFrom.value.validate((valid: boolean) => {
        if (!valid) return
        onSubmit({
          ...state.formData
        })
      })
    }

    return {
      ...toRefs(state),
      onCancel,
      onValidate,
      refFrom
    }
  }
})
