/*
 * @Description:
 * @Author: yyx
 * @@后台人员: xxx
 * @Date: 2023-04-14 14:13:21
 * @LastEditors: yyx
 * @LastEditTime: 2023-04-14 14:25:20
 */
import service from '@/utils/request'

export const getListAPI = (data: any): object => {
  return service.request({
    url: '/system/carBrand/list',
    method: 'post',
    data
  })
}

export const addAPI = (data: any): object => {
  return service.request({
    url: '/system/carBrand/add',
    method: 'post',
    data
  })
}

export const editAPI = (data: any): object => {
  return service.request({
    url: '/system/carBrand/edit',
    method: 'post',
    data
  })
}
