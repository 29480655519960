import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, withCtx as _withCtx, createVNode as _createVNode, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "main-bg" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_button = _resolveComponent("el-button")
  const _component_c_table = _resolveComponent("c-table")
  const _component_Dialog = _resolveComponent("Dialog")
  const _directive_hasAuth = _resolveDirective("hasAuth")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _withDirectives(_createVNode(_component_el_button, {
      type: "primary",
      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onToggle('isShow', null, '新增车品牌')))
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.btnName('auto-brand-add')), 1)
      ]),
      _: 1
    }, 512), [
      [_directive_hasAuth, 'auto-brand-add']
    ]),
    _createVNode(_component_c_table, {
      dataSource: _ctx.dataSource,
      onPageCurrentChange: _ctx.pageCurrentChange,
      onPageSizeChange: _ctx.pageSizeChange,
      class: "mt20"
    }, {
      action: _withCtx((scope) => [
        _withDirectives(_createVNode(_component_el_button, {
          type: "text",
          size: "small",
          onClick: ($event: any) => (_ctx.onToggle('isShow', scope.row, '编辑品牌'))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.btnName('auto-brand-edit')), 1)
          ]),
          _: 2
        }, 1032, ["onClick"]), [
          [_directive_hasAuth, 'auto-brand-edit']
        ])
      ]),
      _: 1
    }, 8, ["dataSource", "onPageCurrentChange", "onPageSizeChange"]),
    (_ctx.isShow)
      ? (_openBlock(), _createBlock(_component_Dialog, {
          key: 0,
          modelValue: _ctx.isShow,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_ctx.isShow = $event)),
          title: _ctx.title,
          row: _ctx.currentRow,
          "onUpdate:list": _ctx.getList
        }, null, 8, ["modelValue", "title", "row", "onUpdate:list"]))
      : _createCommentVNode("", true)
  ]))
}