/*
 * @Description:
 * @Author: yyx
 * @@后台人员: xxx
 * @Date: 2023-04-14 14:13:21
 * @LastEditors: yyx
 * @LastEditTime: 2023-04-14 15:00:04
 */

// 表格配置
export const getTableConfig = () => {
  // table 通用属性
  const common = {
    // table 有多选时设置
    isSelection: false,
    // table loading
    loading: false,
    // table 是否为斑马纹
    stripe: false,
    // table 的尺寸 medium / small / mini
    size: 'small',
    // table 是否开启索引
    isIndex: false,
    // table 索引 label
    indexLabel: '编号',
    // table 数据
    data: []
  }

  // table 分页
  const pagination = {
    // 是否展示分页
    isShow: false,
    // 总条数
    total: 0,
    // 每页显示条目个数
    pageSize: 20,
    // 当前页数
    currentPage: 1,
    // 每页显示个数选择器的选项设置
    pageSizes: [10, 20, 40, 80, 100]
  }

  // table 列数据
  const column = [
    {
      key: 'name',
      label: '品牌名称',
      width: '120'
    },
    {
      key: 'brandLetter',
      label: '字母',
      width: '120'
    },
    {
      key: 'action',
      label: '操作',
      width: '120',
      slot: 'action'
    }
  ]

  return {
    ...common,
    pagination,
    column
  }
}
